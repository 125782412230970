import * as Sentry from '@sentry/node';
import 'react-image-lightbox/style.css'; 
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/globals.css'

import NextNprogress from 'nextjs-progressbar';

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    enabled: process.env.NODE_ENV === 'production',
    // enabled: true,
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN
  });
}

function MyApp({ Component, pageProps, err }) {
  return(
    <div>
      <NextNprogress
        color="#ff7f50"
        startPosition={0.3}
        stopDelayMs={0}
        height={5}
      />
      <Component {...pageProps} err={err} />
    </div>
  )
}

export default MyApp
